// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-renal-cell-carcinoma-js": () => import("./../../../src/pages/advanced-renal-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-advanced-renal-cell-carcinoma-js" */),
  "component---src-pages-dosing-js": () => import("./../../../src/pages/dosing.js" /* webpackChunkName: "component---src-pages-dosing-js" */),
  "component---src-pages-endometrial-carcinoma-js": () => import("./../../../src/pages/endometrial-carcinoma.js" /* webpackChunkName: "component---src-pages-endometrial-carcinoma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mechanisms-of-action-js": () => import("./../../../src/pages/mechanisms-of-action.js" /* webpackChunkName: "component---src-pages-mechanisms-of-action-js" */),
  "component---src-pages-patient-support-js": () => import("./../../../src/pages/patient-support.js" /* webpackChunkName: "component---src-pages-patient-support-js" */),
  "component---src-pages-professional-resources-js": () => import("./../../../src/pages/professional-resources.js" /* webpackChunkName: "component---src-pages-professional-resources-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

